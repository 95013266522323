import React, { useCallback, useState } from "react";
import { TouchableOpacity, StyleSheet, ScrollView } from "react-native";
import { View, Text, Card, Wrapper } from "@components/atoms/Themed";
import Icon from "@components/atoms/Icon";
import Modal from "@components/molecules/Modal";
import Spacer from "@components/atoms/Spacer";
import Fonts from "@constants/Fonts";
import Colors from "@constants/Colors";
import Divider from "@components/atoms/Divider";
import GradientButton from "@components/atoms/GradientButton";
import openURL from "@lib/util/openUrl";
import { instagramGuideUrl } from "@constants/Env";

type Props = {
  onConfirmed: () => void;
  onClose: () => void;
};

export default function InstagramGuide({
  onConfirmed,
  onClose,
}: Props): JSX.Element {
  const [proAccount, setProAccount] = useState<boolean>(false);
  const [usageAgreement, setUsageAgreement] = useState<boolean>(false);

  const openNotion = useCallback(() => {
    openURL(instagramGuideUrl);
  }, []);
  return (
    <Modal
      animationType="slide"
      header
      headerTitle="Instagram連携"
      onRequestClose={onClose}
      visible
    >
      <Spacer height={8} />
      <ScrollView>
        <Wrapper>
          <View style={styles.content}>
            <Card style={styles.card}>
              <Text style={styles.title}>
                連携前に確認し、チェックをいれてください
              </Text>

              <TouchableOpacity
                onPress={() => setProAccount(!proAccount)}
                style={styles.row}
              >
                {proAccount ? (
                  <Icon color={Colors.green} name="checkBoxFill" size={24} />
                ) : (
                  <Icon color={Colors.gray} name="checkboxEmpty" size={24} />
                )}
                <Spacer width={8} />
                <View style={styles.item}>
                  <Text style={styles.text}>
                    Instagramアカウントはプロアカウントですか？
                  </Text>
                </View>
              </TouchableOpacity>

              <View style={styles.link}>
                <TouchableOpacity onPress={openNotion} style={styles.move}>
                  <Text style={styles.moveText}>不明な場合</Text>
                  <Spacer width={4} />
                  <Icon color={Colors.black} name="next" size={18} />
                </TouchableOpacity>
              </View>

              <Spacer height={16} />

              <TouchableOpacity
                onPress={() => setUsageAgreement(!usageAgreement)}
                style={styles.row}
              >
                {usageAgreement ? (
                  <Icon color={Colors.green} name="checkBoxFill" size={24} />
                ) : (
                  <Icon color={Colors.gray} name="checkboxEmpty" size={24} />
                )}

                <Spacer width={8} />
                <View style={styles.item}>
                  <Text style={styles.text}>
                    本連携によりInstagramのアカウントや本サービスに関する投稿についての情報を広告主に開示させていただきます。情報の開示に同意いただけますか？
                  </Text>
                </View>
              </TouchableOpacity>
            </Card>

            <Spacer height={16} />

            <Card>
              <TouchableOpacity onPress={openNotion} style={styles.fail}>
                <View style={styles.item}>
                  <Text style={styles.moveText}>連携に失敗する場合</Text>
                </View>

                <Icon color={Colors.black} name="next" size={18} />
              </TouchableOpacity>
            </Card>
          </View>
        </Wrapper>
      </ScrollView>
      <Divider />
      <Card style={styles.footer}>
        <GradientButton
          disabled={!proAccount || !usageAgreement}
          onPress={onConfirmed}
          title="連携する"
        />
        {(!proAccount || !usageAgreement) && (
          <>
            <Spacer height={8} />
            <Text style={styles.error}>チェックをいれてください</Text>
          </>
        )}
      </Card>
    </Modal>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  card: {
    paddingVertical: 24,
    paddingHorizontal: 16,
  },
  title: {
    ...Fonts.xlb160,
  },
  footer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
  },
  row: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingVertical: 16,
  },
  item: {
    flex: 1,
  },
  text: {
    ...Fonts.xlr160,
  },
  link: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  move: {
    flexDirection: "row",
  },
  moveText: {
    ...Fonts.xlr100,
  },
  fail: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingVertical: 24,
    paddingHorizontal: 16,
  },
  error: {
    color: Colors.orange,
  },
});
